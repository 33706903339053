import './index.css'
import PropTypes from "prop-types";
import {Button, Divider, message, Modal, Space, Tag} from 'antd';
import {getId} from '../../helpers/file';
import QRCode from "react-qr-code";
import {CopyOutlined} from "@ant-design/icons";

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

/**
 * 格式化日期
 * @param date
 */
function formatDate(date: any) {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':')
  );
}

/**
 * 获取日期
 * @param date
 */
function getDate(date: any) {
  const dateTime = formatDate(date);
  const [dt] = dateTime.split(' ');
  return dt;
}

/**
 * 获取时间
 * @param date
 */
function getTime(date: any) {
  const dateTime = formatDate(date);
  const [, tm] = dateTime.split(' ');
  return tm;

}

/**
 * 获取文件后缀
 */
const getFileType = (contentType = '', fileName = '') => {
  const lastIndex = fileName.lastIndexOf('.');
  return fileName.substring(lastIndex + 1).toLowerCase();
}

/**
 * 判断是否为 zip 文件
 * @param fileName
 */
const isZip = (fileName = '') => {
  const type = getFileType('', fileName) || '';
  return 'zip' == type.toLowerCase();
}

/**
 * 提取文件名称
 * @param name
 */
const getName = (name = '') => {
  if (name?.indexOf?.('-')) {
    return name.substring(name.indexOf('-') + 1)
  }
  return name;
}

/**
 * 获取文件的预览图
 * @param url
 */
const getPreview = (url = '') => {
  if (url && url.indexOf("?") > 0) {
    url = url.substring(0, url.indexOf("?"));
  }
  // 返回对应文件类型的后缀
  const map = {
    ['.zip']: 'https://0.z.wiki/autoupload/2022-08-16/4ce28595bb5f4b898f595bcd2f628f8d.zip.svg',
    ['.svg']: 'https://6.z.wiki/autoupload/2022-09-18/4a9ab33c22d54f9f838801f145cb8516.SVG图标.svg',
    ['.pdf']: 'https://0.z.wiki/autoupload/2022-09-09/995ec22a130649e1b8aed3d83a648781.pdf.svg',
    ['.mp4']: 'https://4.z.wiki/autoupload/2022-09-16/343f6678edf54cf4a4527e3600f17cb2.MP4.svg',
    ['.html']: 'https://3.z.wiki/autoupload/2022-09-18/dfa2279d57f5479a83103dd9445958f5.html.svg',
    ['.htm']: 'https://3.z.wiki/autoupload/2022-09-18/dfa2279d57f5479a83103dd9445958f5.html.svg',
    ['.js']: 'https://z.wiki/autoupload/20240406/Qe7N.javascript.svg',
    ['.css']: 'https://cdn.z.wiki/autoupload/2022-09-18/f44b7e3b35de4dc2a33640619b64ca5c.CSS.svg',
    ['.java']: 'https://cdn.z.wiki/autoupload/2022-09-19/447070ea92a547e78d40a096d32a9c45.java.svg',
    ['.jsx']: 'https://cdn.z.wiki/autoupload/2022-09-19/95312e34636942eba6f57174c6c0c62f.jsx.svg',
    ['.vue']: 'https://z.wiki/autoupload/20240406/ZLQW.vue-config.svg',
    ['.sh']: 'https://z.wiki/autoupload/20240406/DOtp.SHELL.svg',
    ['.jar']: 'https://5.z.wiki/autoupload/2022-09-19/fd812b74b27a4f10a63b7459ebd3cef6.JAR.svg',
    ['.class']: 'https://8.z.wiki/autoupload/2022-09-19/a388ddc5203643daaa41c2b1ea1b883a.class.svg',
    ['.php']: 'https://8.z.wiki/autoupload/2022-09-18/80dbd4edcc4b4d2a96b5f3bda9f8ff25.PHP.svg',
    ['.ico']: 'https://4.z.wiki/autoupload/2022-09-18/14cad0db1f174478be7d038c740f95ec.图片.svg',
    ['.mp3']: 'https://5.z.wiki/autoupload/2022-09-18/6a714ce4fcb44a1b93a844388e510533.MP3.svg',
    ['.doc']: 'https://3.z.wiki/autoupload/2022-09-18/64880d19208a4eef99ce63c2f8494f68.doc.svg',
    ['.docx']: 'https://3.z.wiki/autoupload/2022-09-18/64880d19208a4eef99ce63c2f8494f68.doc.svg',
    ['.txt']: 'https://7.z.wiki/autoupload/20221224/5eVn.txt-ext.svg',
    ['.json']: 'https://7.z.wiki/autoupload/2022-09-18/194c5e66be6b44a5b88d95a7eea0c05e.JSON.svg',
    ['.md']: 'https://5.z.wiki/autoupload/2022-09-18/e0aa344b2d924b228d6a01026ed4b4e0.markdown.svg',
    ['.ppt']: 'https://2.z.wiki/autoupload/2022-09-18/728eb566f0644d108ab1d98f54561df5.ppt.svg',
    ['.pptx']: 'https://2.z.wiki/autoupload/2022-09-18/728eb566f0644d108ab1d98f54561df5.ppt.svg',
    ['.psb']: 'https://2.z.wiki/autoupload/2022-09-18/8a023049b2e749ff911099b338d77383.PS.svg',
    ['.xml']: 'https://2.z.wiki/autoupload/2022-09-18/b4247c301b0948708582c3f030cf664b.XML.svg',
    ['.csv']: 'https://2.z.wiki/autoupload/2022-09-18/306ac35844fa4117a1d317c91663b66c.CSV.svg',
    ['.key']: 'https://6.z.wiki/autoupload/2022-09-18/1326995267e345f192b9ecfdec36196a.keynote.svg',
    ['.torrent']: 'https://4.z.wiki/autoupload/2022-09-18/0942076a759345a4a28e5105439da15e.file_bt.svg',
    ['.xls']: 'https://6.z.wiki/autoupload/2022-09-18/8ed51cfaf5f04d88b7ef0f0eb13e0035.excel.svg',
    ['.xltx']: 'https://6.z.wiki/autoupload/2022-09-18/8ed51cfaf5f04d88b7ef0f0eb13e0035.excel.svg',
    ['.xlsm']: 'https://6.z.wiki/autoupload/2022-09-18/8ed51cfaf5f04d88b7ef0f0eb13e0035.excel.svg',
    ['.xlsb']: 'https://6.z.wiki/autoupload/2022-09-18/8ed51cfaf5f04d88b7ef0f0eb13e0035.excel.svg',
    ['.alfredworkflow']: 'https://1.z.wiki/autoupload/20221224/FwUM.workflow.svg',
    ['.tiff']: 'https://3.z.wiki/autoupload/20221224/x2Fz.Tiff.svg',
    ['.exe']: 'https://5.z.wiki/autoupload/20221224/PvKj.exe.svg',
    ['.7z']: 'https://0.z.wiki/autoupload/20221224/NpA2.7z.svg',
    ['.rar']: 'https://3.z.wiki/autoupload/20221224/uRGw.rar.svg',
    ['.gz']: 'https://6.z.wiki/autoupload/20221224/GUg7.gzip.svg',
    ['.ts']: 'https://7.z.wiki/autoupload/20221224/X169.typescript-def.svg',
    ['.config']: 'https://z.wiki/autoupload/20240406/tzaa.config---.svg',
    ['.dmg']: 'https://5.z.wiki/autoupload/20221224/0IXD.dmg.svg'
  };
  let icon = 'https://5.z.wiki/autoupload/2022-09-18/a426d828fa6242cdb9698fbe32a430a4.unknownfile.svg';
  Object.keys(map).forEach(suffix => {
    if (url.endsWith(suffix)) {
      icon = (map as any)[suffix];
    }
  })
  return icon;
}

/**
 * 判断 url 是否为照片
 * @param url
 */
const isImg = (url = '') => {
  if (url && url.indexOf("?") > 0) {
    url = url.substring(0, url.indexOf("?"));
  }
  // 图片后缀，预览图中如果是图片需要显示照片内容，否则显示文件类型图标
  const imgSuffixes = ['.bmp', '.jpg', '.jpeg', '.png', '.gif', '.webp', '.ico', '.svg'];
  return imgSuffixes.some(imgSuffix => {
    return url && url.toLowerCase().endsWith(imgSuffix);
  })
}

/**
 *
 * @author dongdong.fdd
 * @Company AutoNavi Co.,Ltd.
 * @date 2023/7/29 15:30
 */
// @ts-ignore
function UploadItem({id, fileName, time, url, rawUrl, contentType, size, onDelete}) {
  const [messageApi, contextHolder] = message.useMessage();

  const copyUrl = (content = '') => {
    navigator.clipboard.writeText(content).then(() => {
      messageApi.open({
        type: 'success',
        content: '复制成功',
      });
    });
  }

  const handleCopyOriginUrl = (content = '') => {
    const key = 'origin_token';
    if (localStorage.getItem(key) && /^\d\d\d$/.test(localStorage.getItem(key) || '')) {
      navigator.clipboard.writeText(content).then(() => {
        messageApi.open({
          type: 'success',
          content: '复制成功',
        });
      });
    } else {
      // 没有口令
      const value = window.prompt("请输入口令,口令可以在博主的公众号发送“口令”获取 https://z.wiki/misc/contact.html");
      if (value && /^\d\d\d$/.test(value)) {
        localStorage.setItem(key, value);
        navigator.clipboard.writeText(content).then(() => {
          messageApi.open({
            type: 'success',
            content: '复制成功',
          });
        });
      } else {
        alert('口令错误，,口令可以在博主的公众号发送“口令”获取，公众号链接： https://z.wiki/misc/contact.html')
      }
    }
  }

  const handleImgLoadError = (e: any) => {
    console.info('e is', e);
    const img = e.target as HTMLImageElement;
    img.onerror = null;
    img.src = 'https://z.wiki/autoupload/20230831/l32P.%E5%9B%BE%E7%89%87%E5%8A%A0%E8%BD%BD%E5%A4%B1%E8%B4%A5_%282%29.svg';
  }

  return (
    <div
      className="UploadItem"
      style={{
        width: '324px',
        marginBottom: '28px',
        "backgroundColor": "#FFFFFF",
        "borderRadius": "6px",
        overflow: 'hidden',
      }}
    >
      {contextHolder}
      <img
        className="img"
        src={isImg(url) ? url : getPreview(url)}
        onError={handleImgLoadError}
        style={{
          height: '188px',
          width: '100%',
          backgroundRepeat: 'no-repeat',
          objectFit: "fill",
          backgroundSize: "unset",
          backgroundPosition: "center center",
          padding: "15px",
          boxSizing: "border-box",
        }}
      />
      <div
        className="summary"
        style={{
          paddingTop: '12px',
          paddingLeft: '14px',
          paddingRight: '14px',
          paddingBottom: '20px',
        }}
      >
        <div className="info">
          <h3
            className="file-name"
            style={{
              paddingBottom: '7px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              margin: 0,
              "height": "22px",
              "fontFamily": "PingFangSC",
              "fontWeight": 400,
              "fontSize": "16px",
              "color": "#21242E",
              "letterSpacing": "0"
            }}
            title={decodeURIComponent(getName(decodeURIComponent(fileName)))}
          >
            {decodeURIComponent(getName(decodeURIComponent(fileName)))}
          </h3>
          <span
            className="text-info"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              "height": "22px",
              "fontFamily": "PingFangSC",
              "fontWeight": 400,
              "fontSize": "16px",
              "color": "#757D8B",
              "letterSpacing": "0"
            }}
          >
            <span>格式：{getFileType(contentType, fileName)}</span>
            <span>{getDate(new Date(time))}</span>
          </span>
          <span
            className="text-info"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              "height": "22px",
              "fontFamily": "PingFangSC",
              "fontWeight": 400,
              "fontSize": "16px",
              "color": "#757D8B",
              "letterSpacing": "0"
            }}
          >
            <span>大小：{size || '-'}</span>
            <span>{getTime(new Date(time))}</span>
          </span>
        </div>
        <hr
          style={{
            marginTop: '14px',
            marginBottom: '14px',
            "borderColor": "#e6eaf0",
            "borderTopWidth": "0px"
          }}
        />
        <div className="links">
          <div
            className="first-line"
            style={{
              marginBottom: '8px'
            }}
          >
            <Space split={<Divider type="vertical"/>} size={18} style={{"display": "flex", "width": "100%", "justifyContent": "space-between"}}>
            <span
              className={'link'}
              onClick={() => {
                copyUrl(url)
              }}
            >链接</span>
              <span
                className={'link'}
                onClick={() => {
                  copyUrl(`![${getName(fileName)}](${url})`)
                }}
              >
              MD
            </span>
              {
                isZip(fileName) && (
                  <>
                    <span
                      className={'link'}
                      onClick={() => {
                        const unzipLoadingKey = 'unzipLoadingKey';
                        message.info({
                          content: "解压中...",
                          key: unzipLoadingKey
                        })
                        const decodedUrl = decodeURIComponent(url);
                        const objectKey = url.substring(decodedUrl.indexOf('autoupload/'));
                        fetch(`/img/unzip-list?objectKey=${objectKey}`).then(res => res.json()).then(res => {
                          message.destroy(unzipLoadingKey);
                          const files = (res.data || []).map((file: {objectKey: string, size: string}) => {
                            const newObjectKey = file.objectKey.replace(decodeURIComponent(objectKey) + '/', '');
                            return {
                              objectKey: newObjectKey,
                              size: file.size
                            }
                          });
                          Modal.info({
                            title: '解压内容',
                            width: '70%',
                            content: (
                              <div
                                className={"unzip-list-ctn"}
                                style={{
                                  marginRight: '34px'
                                }}
                              >
                                <p className={"url"}>
                                  <a href={url} target={'_blank'}>{decodedUrl}</a>
                                </p>
                                <div className="file-list">
                                  <ul
                                    style={{
                                      paddingLeft: '15px',
                                      maxHeight: 'calc(100vh - 300px)',
                                      overflow: 'auto'
                                  }}
                                  >
                                    {
                                      files.map(({objectKey, size}: {objectKey: string, size: string}) => {
                                        return (
                                          <li key={objectKey}>
                                            <a href={`${url}/${objectKey}`} target={'_blank'}>{decodeURIComponent(objectKey)}</a>
                                            &nbsp;
                                            <Tag
                                              color="magenta"
                                              icon={
                                                <CopyOutlined
                                                  style={{
                                                    cursor: 'pointer',
                                                  }}
                                                  onClick={() => {
                                                    navigator.clipboard.writeText(`${url}/${objectKey}`).then(() => {
                                                      messageApi.open({
                                                        type: 'success',
                                                        content: '复制成功',
                                                      });
                                                    })
                                                  }}
                                                />
                                              }
                                            >
                                              {size}
                                            </Tag>
                                          </li>
                                        )
                                      })
                                    }
                                  </ul>
                                </div>
                              </div>
                            )
                          })
                        })
                      }}
                    >
                    解压
                  </span>
                  </>
                )
              }
              {
                !isZip(fileName) && (
                  <>
                    <span
                      className={'link'}
                      onClick={() => {
                        fetch(`/img/detail?id=${id}&uid=${getId()}`).then(r => r.json()).then(record => {
                          copyUrl(record.base64);
                        })
                      }}
                    >
                    Base64
                  </span>
                  </>
                )
              }
              <span
                className={'link'}
                onClick={() => {
                  handleCopyOriginUrl(rawUrl || url.replace(/(([\dcdn]+.))?z.wiki/, 'z.wiki'))
                }}
              >
              原图
            </span>
            </Space>
          </div>
          <div
            className="second-line"
          >
            <Space split={<Divider type="vertical"/>} size={16} style={{"display": "flex", "width": "100%", "justifyContent": "space-between"}}>
            <span
              className={'link'}
              onClick={() => {
                window.open(url, '_blank');
              }}
            >
              访问
            </span>
              <span
                className={'link'}
                onClick={() => {
                  console.info('二维码')
                  Modal.info({
                    title: '二维码',
                    content: (
                      <div
                        className={"qr-code-ctn"}
                        style={{
                          marginRight: '34px'
                        }}
                      >
                        <p className={"url"}>
                          <a href={url} target={'_blank'}>{decodeURIComponent(url)}</a>
                        </p>
                        <div className="qr-code">
                          <QRCode
                            size={256}
                            style={{height: "auto", maxWidth: "100%", width: "100%"}}
                            value={url}
                            viewBox={`0 0 256 256`}
                          />
                        </div>
                      </div>
                    )
                  })
                }}
              >
                二维码
              </span>
              <span
                className={'link'}
                onClick={() => {
                  fetch(`/u/save?url=${encodeURIComponent(url)}`, {
                    "method": "POST",
                  }).then(res => res.json()).then(json => {
                    const shortUrl = `https://z.wiki/u/${json.shortId}`;
                    Modal.info({
                      title: '短网址',
                      content: (
                        <div
                          className={"qr-code-ctn"}
                          style={{
                            marginRight: '34px'
                          }}
                        >
                          <p className={"url"}>
                            <a href={shortUrl} target={'_blank'}>{shortUrl}</a>
                          </p>
                        </div>
                      ),
                      footer: (
                        <div className="footer-info" style={{float: 'right'}}>
                          <Space align={'end'}>
                            <Button
                              type="primary"
                              onClick={() => {
                                window.navigator.clipboard.writeText(shortUrl).then(() => {
                                  Modal.destroyAll();
                                });
                              }}
                            >
                              复制
                            </Button>
                            <Button
                              type={'default'}
                              onClick={() => {
                                Modal.destroyAll();
                              }}
                            >
                              关闭
                            </Button>
                          </Space>
                        </div>
                      )
                    })
                  });
                }}
              >
              短网址
            </span>
              <span
                className={'link dlt-link'}
                onClick={() => {
                  console.info('onClick')
                  messageApi.open({
                    type: 'info',
                    content: '删除中',
                  });
                  fetch(`/img/delete?id=${id}&uid=${getId()}`, {
                    method: 'DELETE'
                  }).then(res => res.json()).then((flag) => {
                    if (flag) {
                      messageApi.open({
                        type: 'success',
                        content: '删除成功'
                      })
                      onDelete && onDelete();
                    } else {
                      messageApi.open({
                        type: 'error',
                        content: '删除失败，请刷新重试。'
                      })
                    }
                  })
                }}
              >
              删除
            </span>
            </Space>
          </div>
        </div>
      </div>
    </div>
  );
}

UploadItem.propTypes = {
  id: PropTypes.number.isRequired,
  fileName: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  size: PropTypes.string,
}

export default UploadItem
